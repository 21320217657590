import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import I18n from "../i18n/i18n";
import Translator from "../i18n/Translator";
import logo from "../../assets/logoTP.png";
import NavDropdown from "./NavDropdown";
import NavDropdownGroup from "./NavDropdownGroup";

const Nav = ({ contactModal, signIn, isNews }) => {
  const [offsetY, setOffsetY] = useState(0);
  const history = useHistory();
  const [openId, setOpenId] = useState(null);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  return (
    <div className='header'>
      <div style={{ transform: `translateY(${offsetY * 0.5}px)` }}>
        <div className='top-bar'>
          <div>
            <img
              src={logo}
              style={{ width: 290, marginTop: 10, marginLeft: 10 }}
              alt='logo'
            />
          </div>

          <div className='top-nav'>
            <Link className='nav-item' to='/'>
              <Translator path='Home' />
            </Link>
            <Link className='nav-item' to='/biography'>
              <Translator path='AboutNick' />{" "}
            </Link>
            <span
              style={{ cursor: "pointer" }}
              onClick={contactModal}
              className='nav-item'
              to='/contact'
            >
              <Translator path='Contact' />
            </span>
          </div>
        </div>

        <div className='lower-nav'>
          <ReactBootStrap.Navbar
            collapseOnSelect
            expand='lg'
            bg='dark'
            variant='dark'
          >
            <ReactBootStrap.Navbar.Brand href='/'></ReactBootStrap.Navbar.Brand>
            <ReactBootStrap.Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <ReactBootStrap.Navbar.Collapse id='responsive-navbar-nav'>
              <div className='nav-dropdowns'>
                <NavDropdown
                  setOpen={setOpenId}
                  open={openId === 1}
                  id={1}
                  links={[
                    {
                      path: "/videos",
                      label: "Media",
                      anchor: false,
                    },
                    {
                      path: "/gallery",
                      label: "PhotoGallery",
                      anchor: false,
                    },
                  ]}
                  title={<Translator path='Media' />}
                />
                <NavDropdown
                  setOpen={setOpenId}
                  id={2}
                  open={openId === 2}
                  links={[
                    {
                      path: "/ota_videos",
                      label: "TutorialVideos",
                      anchor: false,
                    },
                    {
                      path: "https://onlinetromboneacademy.com/courses/lesson",
                      label: "PrivateLessons",
                      anchor: true,
                    },
                    {
                      path: "/sheet_music",
                      label: "SheetMusic",
                      anchor: false,
                    },
                    {
                      path: "https://onlinetromboneacademy.com/",
                      label: "Products",
                      anchor: true,
                    },
                  ]}
                  title={<Translator path='OTA' />}
                />
                <NavDropdown
                  id={3}
                  open={openId === 3}
                  setOpen={setOpenId}
                  links={[
                    {
                      path: "/conductor",
                      label: "Conductor",
                      anchor: false,
                    },
                    {
                      path: "/livemusic",
                      label: "LiveMusic",
                      anchor: false,
                    },
                  ]}
                  title={<Translator path='Bookings' />}
                />
                <Link
                  className='nav-link__single--instruments'
                  to='/instruments'
                >
                  <Translator path='InstrumentGallery' />
                </Link>
                {isNews && (
                  <Link className='nav-link__single' to='/news'>
                    <Translator path='News' />
                  </Link>
                )}
              </div>
              <div className='lower-nav-right-buttons'>
                {/* <span onClick={signIn} className='vip-button'>
                  VIP LOGIN
                </span> */}
                <div className='navbar-languages'>
                  <I18n />
                </div>
              </div>
            </ReactBootStrap.Navbar.Collapse>
          </ReactBootStrap.Navbar>
        </div>
      </div>
    </div>
  );
};

export default Nav;
