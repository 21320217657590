import React, { useState, useEffect } from "react";
import Translator from "../i18n/Translator";
import TitleBar from "../TitleBar";
import marked from "marked";
import { getReviews } from "../../contentful";
import { Row, Col } from "react-bootstrap";

const Press = () => <h2>Press</h2>

export default Press;
