import React, { useEffect, useState } from "react";
import Translator from "./i18n/Translator";
import logo from "../assets/imgs/NickcloseUp.jpg";
import OTAlogo from "../assets/imgs/OTAlogo.jpg";
import { getFormText } from "../contentful";

import SignUpForm from "./SignUpForm";

const SignUpFormHome = () => {
  const [formText, setFormText] = useState({});

  useEffect(() => {
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

    getFormText(lang).then((data) => setFormText(data[0].fields));
  }, []);
  return (
    <div className='sign-up-form-hero'>
      <div className='sign-up-form-container'>
        <div>
          <h4>{formText.title}</h4>
          <h5>{formText.subtitle} </h5>
          <p>{formText.motivationText} </p>
        </div>
        <SignUpForm buttonText={formText.buttonText} />
      </div>
    </div>
  );
};

export default SignUpFormHome;
