import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleNewsItem } from "../contentful";
import marked from "marked";
import { Link } from "react-router-dom";
import Translator from "./i18n/Translator";
import NewsStickerEn from "../assets/imgs/newsStickerEn.png";
import NewsStickerDe from "../assets/imgs/newsStickerDE.png";

const NewsDetailsPage = () => {
  const { id } = useParams();

  const [news, setNews] = useState({});
  const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleNewsItem(id, lang).then((data) => setNews(data[0].fields));
  }, [id]);

  return (
    <div className='page-content news-details'>
      <div className='news-details__sticker'>
        <img src={lang === "de" ? NewsStickerDe : NewsStickerEn} />
      </div>
      <div className='news-details__header'>
        <div className='news-details__text'>
          <h3>{news.title}</h3>

          {news.subtitle && <h5>{news.subtitle}</h5>}
          {news.mainText && (
            <section
              dangerouslySetInnerHTML={{ __html: marked(news.mainText) }}
            />
          )}
        </div>
      </div>

      {news.mainImage && (
        <div className='news-details__image'>
          <img src={news.mainImage.fields.file.url} />
        </div>
      )}
      <div>
        <Link className='btn btn-sholl-light' to='/news'>
          <Translator path='MoreNews' /> >>>
        </Link>
      </div>
    </div>
  );
};

export default NewsDetailsPage;
