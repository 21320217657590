export default {
  translations: {
    Quote: "Quote in German",
    AboutNick: "Über Nick",
    Source: "The Times",
    Home: "Home",
    Contact: "Kontakt",
    InputNameLabel: "Enter your name",
    InputEmailLabel: "Email",
    FormText: "Suchst du den GOLDENEN Ton?",
    FormText2: "Entdecke Nick’s Profi Tips in seinem 3-teiliger Minikurs",
    FormText3: "Sign up here for a FREE 3-step trombone tutorial:",
    OTA: "Online Trombone Academy",
    NextLiveSession: "Next Live Session:",
    LiveMusic: "Live Musik",
    Bookings: "Buchungen",
    LiveSessions: "Live Events",
    PrivateLessons: "Privatunterricht",
    SheetMusic: "Noten",
    PhotoGallery: "Fotogalerie",
    FollowNick: "Folge Nick's Trombone Academy",
    Send: "Senden",
    SignUpButton: "Melde dich hier an für den KOSTENLOSEN Kurs:",
    JoinTheMailingList: "Schicke mir jetzt den Kurs!",
    Conductor: "Dirigent",
    AboutTheAcademy: "Über die Academy",
    PayWithPaypal: "Bezahle mit Paypal um deinen Platz zu reservieren",
    NextDate: "Nächster Termin",
    NextDates: "Nächster Termin",
    ContactMe: "Kontaktiere mich",

    ContactText:
      "Ich höre gerne von dir! Schreibe mir eine Email und ich melde mich zeitnah.",
    WriteMessage: "Deine Nachricht",
    GalleryClick: "Klick auf ein Bild für Vollbild Galerie",
    ContactMotivation:
      "Schreib mir eine Nachricht, um ein Angebot für Ihr Event zu bekommen - ich freue mich von Ihnen zu hören.",

    ContactFormName: "Name:",
    ContactFormEmail: "Email:",
    ContactFormMessage: "Nachricht",
    ContactFormButton: "senden",
    Biography: "Biographie",
    Calendar: "Termine",
    Archive: "Archiv",
    Projects: "Projekte",
    Education: "Pädagogik",
    Collaborations: "Kooperationen",
    Media: "Video & Audio",
    Recordings: "Diskographie",
    TutorialVideos: "Tutorial Videos",
    News: "Aktuelles",
    MoreNews: "Mehr Aktuelles",
    Press: "Pressestimmen",
    NewsletterText:
      "Aktuelles zu Konzerte und Aktivitäten gibt es in meinem Newsletter.",
    NewsletterSignUp: "Newsletter abonnieren",
    ViewConcert: "Konzert ansehen",
    ViewArchive: "Konzertarchiv",
    ReadMore: "mehr lesen",
    ViewVideo: "video ansehen",
    ShareThisPage: "Teile diese Seite",
    BackToAllProjects: "zurück zu allen Projekten",
    PhotoDownload: "Herunterladen",
    DownloadFile: "Biographie herunterladen",
    DownloadFree: "Kostenloser Download",
    InstrumentGallery: "Instrument Gallery",

    MoreInfo: "Mehr Info",
    Testimonials: "Feedback zum Unterricht",
    Products: "Produkte",
    Percussionist: "Perkussionist",
    PercussionistInfo:
      "Das Trommel-Feuer vom Djembé Meister Awale Ouro Akpo aus Togo sorgt immer für große Neugier und Begeisterung vom Publikum!",

    LowBrass4tet: "Tiefblech-Quartett",
    TromboneTrio: "Posaunen-Trio",
    All: "Alles",
  },
};
