import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getLiveMusicDetails } from "../../contentful";
import "./LiveMusicStyles.css";
import marked from "marked";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";
import EmbeddedContactForm from "../EmbeddedContactForm";
import GalleryComponent from "../GalleryComponent";

const LiveMusicDetails = () => {
  const [act, setAct] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getLiveMusicDetails(id, switchedLang).then((data) => setAct(data));
  }, []);

  return (
    <div className='live-music-details-page'>
      {act.map((act) => {
        const text = marked(act.fields.description);
        return (
          <>
            <HelmetConfigurator
              description={act.fields.seoDescription}
              keywords={act.fields.seoKeywords}
              title={`${act.fields.title} - Nick Sholl`}
            />
            <div className='page-layout'>
              <div style={{ width: 700 }}>
                <h3>{act.fields.title}</h3>{" "}
                <section dangerouslySetInnerHTML={{ __html: text }} />
                {act.fields.secondImage && (
                  <img
                    src={act.fields.secondImage.fields.file.url}
                    style={{ width: "70%" }}
                    alt=''
                  />
                )}
              </div>
              <div style={{ flex: 1, minWidth: 150, margin: 15 }}>
                <img
                  src={act.fields.mainImage.fields.file.url}
                  style={{ width: "100%" }}
                  alt=''
                />
              </div>
            </div>
            {act.fields.gallery.length > 1 && (
              <div style={{ paddingTop: 50 }}>
                <GalleryComponent gallery={act.fields.gallery} />
              </div>
            )}{" "}
          </>
        );
      })}
      <EmbeddedContactForm />

      <Link className='btn btn-sholl-light' to='/livemusic'>
        Back to all groups
      </Link>
    </div>
  );
};

export default LiveMusicDetails;
