import React from "react";

const Footer = (props) => {
  return (
    <div className='footer'>
      <small>
        Designed and built by Adam Woolf @{" "}
        <a href='https://www.webspinner.eu' target='_blank' rel='noopener'>
          WebSpinner.eu
        </a>
      </small>
    </div>
  );
};

export default Footer;
