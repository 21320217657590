import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Translator from "../i18n/Translator";
import { FaChevronDown } from "react-icons/fa";

const NavDropdown = ({ id, title, links, open, setOpen }) => {
  // const [open, setOpen] = useState(false);

  const renderLink = (link) => {
    return link.anchor ? (
      <a
        key={link.label}
        onClick={() => setOpen(null)}
        className='nav-link'
        href={link.path}
        target='_blank'
      >
        {" "}
        <Translator path={link.label} />
      </a>
    ) : (
      <Link
        key={link.label}
        onClick={() => setOpen(null)}
        to={link.path}
        className='nav-link'
      >
        <Translator path={link.label} />
      </Link>
    );
  };
  return (
    <div onMouseLeave={() => setOpen(false)} className='nav-dropdown'>
      <h5
        className='nav-dropdown__title'
        onClick={() => setOpen(!open ? id : null)}
      >
        {" "}
        {title} <FaChevronDown />
      </h5>
      {open && (
        <div className={"nav-dropdown__links"}>
          {links.map((link) => renderLink(link))}
        </div>
      )}
    </div>
  );
};

export default NavDropdown;
