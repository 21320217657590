import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import Translator from "./i18n/Translator";

const GalleryComponent = ({ gallery }) => {
  return (
    <SRLWrapper>
      <p style={{ fontSize: 12, textAlign: "center", marginTop: 30 }}>
        <Translator path='GalleryClick' />
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {gallery.map((image, i) => (
          <div key={i} className='gallery-wrapper'>
            <img
              src={image.fields.file.url}
              style={{ width: 300 }}
              alt={image.fields.description}
            />
          </div>
        ))}
      </div>
    </SRLWrapper>
  );
};

export default GalleryComponent;
