import React, { useState, useEffect } from "react";
import "./styles.css";
import NewsListItem from "../NewsListItem/NewsListItem";
import { getNewsItems } from "../../contentful";
import { Link } from "react-router-dom";
import Translator from "../i18n/Translator";

const HomepageNews = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

    getNewsItems(lang).then((data) =>
      setNews(data.filter((item) => item.fields.homepage))
    );
  }, []);
  return (
    <div className='news'>
      {news.map((item) => (
        <NewsListItem item={item} homepage />
      ))}
      <Link className='btn btn-sholl' to='/news'>
        <Translator path='MoreNews' /> >>>
      </Link>
    </div>
  );
};

export default HomepageNews;
