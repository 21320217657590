const client = require("contentful").createClient({
  space: "68bt28oo8grn",
  accessToken: "eUDEcgJQCeCJNQxtvAXn6O3Do097IbVIT4oOjWQcgTM",
});
var gt = new Date().toISOString();

const getBiog = (lang) =>
  client
    .getEntries({ content_type: "aboutNick", locale: lang })
    .then((response) => response.items);
const getAudio = () =>
  client
    .getEntries({ content_type: "mp3", order: "fields.order" })
    .then((response) => response.items);
const getVideos = (lang) =>
  client
    .getEntries({ content_type: "video", locale: lang, order: "fields.order" })
    .then((response) => response.items);
const getTestimonials = (lang) =>
  client
    .getEntries({
      content_type: "testimonial",
      locale: lang,
      order: "fields.order",
    })
    .then((response) => response.items);
const getPrivateLessonsPage = (lang) =>
  client
    .getEntries({ content_type: "privateLessonsPage", locale: lang })
    .then((response) => response.items);
const getOTAAboutPage = (lang) =>
  client
    .getEntries({ content_type: "otaPage", locale: lang })
    .then((response) => response.items);
const getLiveSessionsPage = (lang) =>
  client
    .getEntries({ content_type: "liveSessionsPage", locale: lang })
    .then((response) => response.items);
const getOTAVideos = (lang) =>
  client
    .getEntries({
      content_type: "otaVideo",
      locale: lang,
      order: "fields.order",
    })
    .then((response) => response.items);

const getRecordings = (lang) =>
  client
    .getEntries({
      content_type: "recordings",
      order: "-fields.order",
      locale: lang,
    })
    .then((response) => response.items);
const getCollaborations = (lang) =>
  client
    .getEntries({
      content_type: "collaboration",
      locale: lang,
      order: "-fields.order",
    })
    .then((response) => response.items);
const getCollaborationProject = (slug, lang) =>
  client
    .getEntries({ "sys.id": slug, content_type: "collaboration", locale: lang })
    .then((response) => response.items);
const getEducation = (lang) =>
  client
    .getEntries({
      content_type: "education",
      locale: lang,
      order: "-fields.order",
    })
    .then((response) => response.items);
const getEducationProject = (slug, lang) =>
  client
    .getEntries({ "sys.id": slug, content_type: "education", locale: lang })
    .then((response) => response.items);
const getFeaturedVideo = () =>
  client
    .getEntries({ content_type: "featuredVideo" })
    .then((response) => response.items);
const getReviews = () =>
  client
    .getEntries({ content_type: "press", order: "-fields.order" })
    .then((response) => response.items);
const getSheetMusic = (lang) =>
  client
    .getEntries({ content_type: "sheetMusic", locale: lang })
    .then((response) => response.items);
const getGallery = (lang) =>
  client
    .getEntries({
      content_type: "galleryImage",
      locale: lang,
      order: "fields.order",
    })
    .then((response) => response.items);
const getCalendar = () =>
  client
    .getEntries({
      content_type: "calendar",
      order: "fields.date",
      "fields.date[gte]": gt,
    })
    .then((response) => response.items);
const getCalendarArchive = () =>
  client
    .getEntries({
      content_type: "calendar",
      order: "-fields.date",
      "fields.date[lt]": gt,
    })
    .then((response) => response.items);
const getLiveMusic = (lang) =>
  client
    .getEntries({
      content_type: "liveMusic",
      locale: lang,
    })
    .then((response) => response.items);
const getLiveMusicDetails = (slug, lang) =>
  client
    .getEntries({
      content_type: "liveMusic",
      "sys.id": slug,
      locale: lang,
    })
    .then((response) => response.items);

const getConductorPage = (lang) =>
  client
    .getEntries({
      content_type: "conductorPage",
      locale: lang,
    })
    .then((response) => response.items);

const getRollingThunderPage = (lang) =>
  client
    .getEntries({
      content_type: "rollingThunder",
      locale: lang,
    })
    .then((response) => response.items);

const getFormText = (lang) =>
  client
    .getEntries({
      content_type: "formText",
      locale: lang,
    })
    .then((response) => response.items);

const getNewsItems = (lang) =>
  client
    .getEntries({
      content_type: "newItem",
      locale: lang,
      order: "fields.date",
    })
    .then((response) => response.items);

const getSingleNewsItem = (slug, lang) =>
  client
    .getEntries({ "sys.id": slug, content_type: "newItem", locale: lang })
    .then((response) => response.items);

const getContactFormText = (lang) =>
  client
    .getEntries({ content_type: "contactFormText", locale: lang })
    .then((response) => response.items);

const getInstruments = (lang) =>
  client
    .getEntries({
      content_type: "instrument",
      locale: lang,
      order: "fields.order",
    })
    .then((response) => response.items);

export {
  getInstruments,
  getNewsItems,
  getContactFormText,
  getRollingThunderPage,
  getFormText,
  getConductorPage,
  getLiveMusicDetails,
  getLiveMusic,
  getSheetMusic,
  getOTAAboutPage,
  getOTAVideos,
  getLiveSessionsPage,
  getPrivateLessonsPage,
  getTestimonials,
  getAudio,
  getFeaturedVideo,
  getCollaborationProject,
  getEducationProject,
  getEducation,
  getCollaborations,
  getSingleNewsItem,
  getBiog,
  getRecordings,
  getVideos,
  getReviews,
  getGallery,
  getCalendar,
  getCalendarArchive,
};
