import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "./Context";
import detectBrowserLanguage from "detect-browser-language";

import Main from "./components/Main";
import Gallery from "./components/Gallery";
import Posts from "./components/Posts";
import Footer from "./components/Footer";
import Discography from "./components/Discography";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import Calendar from "./components/calendar/Calendar";
import Education from "./components/education/Education";
import Collaborations from "./components/collaborations/Collaborations";
import Videos from "./components/videos/Videos";
import Press from "./components/press/Press";
import Biog from "./components/Biog";
import EducationProjectFull from "./components/education/EducationProjectFull";
import CollaborationSingle from "./components/collaborations/CollaborationSingle";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./components/Navbar/NavBar";
import SocialButtons from "./components/SocialButtons";
import ContactModal from "./components/ContactModal";
import SignInModal from "./components/SignInModal";
import PrivateLessons from "./components/PrivateLessons";
import LiveSessions from "./components/LiveSessions";
import OTAVideos from "./components/OTAVideos/OTAVideos";
import OTAAbout from "./components/OTAAbout/OTAAbout";
import SheetMusic from "./components/SheetMusic/SheetMusic";
import LiveMusic from "./components/LiveMusic/LiveMusic";
import LiveMusicDetails from "./components/LiveMusic/LiveMusicDetails";
import ConductorPage from "./components/ConductorPage/ConductorPage";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
import RollingThunder from "./components/RollingThunder/RollingThunder";
import NewsPage from "./components/NewsPage/NewsPage";
import NewsDetailsPage from "./components/NewsDetailsPage";
import InstrumentGallery from "./components/InstrumentGallery/InstrumentGallery";

const App = () => {
  const {
    contactModal,
    setContactModal,
    setBrowserLang,
    browserLang,
    isNews,
  } = useContext(MainContext);

  useEffect(() => {
    console.log("running");
    setBrowserLang(detectBrowserLanguage());
  }, [browserLang]);

  const [signInModal, setSignInModal] = useState(false);

  const [pswd, setPswd] = useState("");

  let routes;
  if (pswd === "") {
    routes = (
      <>
        <Nav
          signIn={() => setSignInModal(true)}
          contactModal={() => setContactModal(true)}
          isNews={isNews}
        />

        <ContactModal
          close={() => setContactModal(false)}
          show={contactModal}
        />
        <SignInModal close={() => setSignInModal(false)} show={signInModal} />
        <Switch>
          <Route exact path='/' component={Main} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/calendar' component={Calendar} />
          <Route path='/education/:id' component={EducationProjectFull} />
          <Route path='/education' component={Education} />
          <Route path='/collaborations/:id' component={CollaborationSingle} />
          <Route path='/collaborations' component={Collaborations} />
          <Route path='/recordings' component={Discography} />
          <Route path='/videos' component={Videos} />
          <Route path='/press' component={Press} />
          <Route path='/biography' component={Biog} />
          <Route path='/privatemusiclessons' component={PrivateLessons} />
          <Route path='/livesessions' component={LiveSessions} />
          <Route path='/ota_videos' component={OTAVideos} />
          <Route path='/ota_about' component={OTAAbout} />
          <Route path='/sheet_music' component={SheetMusic} />
          <Route path='/livemusic/:id' component={LiveMusicDetails} />
          <Route path='/livemusic' component={LiveMusic} />
          <Route path='/conductor' component={ConductorPage} />
          <Route path='/rolling_thunder' component={RollingThunder} />
          <Route path='/news/:id' component={NewsDetailsPage} />
          <Route path='/news' component={NewsPage} />
          <Route path='/instruments' component={InstrumentGallery} />
        </Switch>
      </>
    );
  } else {
    routes = (
      <div className='content'>
        <h5>This WebSpinner site is currently under development.</h5>
        <p>Please enter your password to continue:</p>
        <input onChange={(e) => setPswd(e.target.value)} type='text' />
      </div>
    );
  }

  return (
    <Router>
      <div style={{ height: "100%" }}>
        {/* <Toolbar  drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer show={this.state.sideDrawerOpen} click={this.drawerToggleClickHandler}/> */}
        {/* {backdrop} */}

        {/* <main style={{marginTop:'56px'}}>
      </main>    */}
        {routes}

        {/* <NewsletterButton/> */}
        <SocialButtons />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
