import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../Context";
import marked from "marked";
import { getConductorPage } from "../../contentful";
import GalleryComponent from "../GalleryComponent";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";
import "./ConductorPageStyles.css";

const ConductorPage = () => {
  const { browserLang } = useContext(MainContext);
  const [content, setContent] = useState([]);

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getConductorPage(switchedLang).then((data) => setContent(data));
  }, []);
  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick Sholl begeistert jedes Publikum mit seinem ausdrucksvollen Dirigat."
            : "Nick Sholl continues to excite audiences with his unique brand of conducting."
        }
        keywords={
          browserLang === "de"
            ? "freischaffender dirigent blasorchester sinfonieorchester posaunenchor "
            : "freelance conductor, wind band, orchestra, concert "
        }
      />
      {content.map((content) => {
        const text = marked(content.fields.text);
        const quote = marked(content.fields.quote);

        return (
          <div style={{ textAlign: "center" }}>
            <h3>{content.fields.title}</h3>
            <div style={{ margin: "auto" }}>
              <section
                className='conductor-page__header'
                dangerouslySetInnerHTML={{ __html: quote }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <img
                src={content.fields.portrait.fields.file.url}
                style={{ height: 450 }}
                alt='main'
              />
              <section
                style={{ textAlign: "left", width: 500, paddingLeft: 30 }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>

            {content?.fields.gallery && (
              <GalleryComponent gallery={content.fields.gallery} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ConductorPage;
