import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import Translator from "./i18n/Translator";
import emailjs from "emailjs-com";

const ContactModal = ({ show, close }) => {
  const form = useRef();
  const emailJSID = "user_WlnDXosQ6uH6Ux18D5Pz9";

  const sendMail = (e) => {
    e.preventDefault();

    emailjs.sendForm("gmail", "website", form.current, emailJSID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
  };
  return (
    <Modal show={show}>
      <div className='contact-modal'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <h3>
            <Translator path='Contact' />
          </h3>
          <FaTimesCircle style={{ cursor: "pointer" }} onClick={close} />
        </div>
        <p>{/* <Translator path='ContactText' /> */}</p>
        {/* <p>
          I would be happy to hear from you at{" "}
          <a href='mailto:nicksholl1986@gmail.com'>nicksholl1986@gmail.com</a>
        </p> */}

        <form
          action='https://formspree.io/f/mrgodwgv'
          method='POST'
          // className='form-group contact-form '
          // onSubmit={sendMail}
          ref={form}
        >
          <input
            name='name'
            className='form-control'
            type='text'
            placeholder='Name'
          />

          <input
            name='reply_to'
            className='form-control'
            type='text'
            placeholder='Email'
          />

          <Translator path='WriteMessage' />
          <textarea name='message' className='form-control' rows={15} />

          <button className='btn btn-sholl' type='submit'>
            <Translator path='Send' />
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ContactModal;
