import React, { useContext } from "react";
import { MainContext } from "../Context";
import "../styles/styles.css";
import Hero from "./hero/Hero";

import TestimonialsSlider from "./TestimonialsSlider/TestimonialsSlider";
import LiveSessionsHero from "./LiveSessionsHero/LiveSessionsHero";
import HelmetConfigurator from "./HelmetConfigurator/HelmetConfigurator";
import LeadMagnet from "./LeadMagnet/LeadMagnet";
import HomepageNews from "./HomepageNews/HomepageNews";
const Main = () => {
  const { browserLang, isHomepageNews } = useContext(MainContext);
  return (
    <>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick Sholl ist studierter Blechbläser und spezialisiert darauf, PosaunistInnen eine solide Grundlage zu vermitteln. Hol dir hier sein kostenloses  Video-Training “Goldener Klang”. "
            : "Nick Sholl studied the Bass Trombone and is specialised in giving trombonists a solid foundation. Check out his free ‘Golden Sound’ Trombone Training here."
        }
        keywords={
          browserLang === "de"
            ? "nick sholl posaune kostenloses video training kurs"
            : "nick, sholl, bass trombone, video course, free"
        }
      />
      <div>
        <LeadMagnet />
        <Hero />

        {isHomepageNews && <HomepageNews />}
        <TestimonialsSlider />
      </div>
    </>
  );
};

export default Main;
