import React from 'react'
import {Modal} from 'react-bootstrap'
import {FaTimesCircle} from 'react-icons/fa'

const SignInModal = ({show, close}) => {
    return (
       <Modal show={show} >
           <div className="contact-modal"  >
               <div style={{display:'flex', justifyContent:'space-between', marginBottom:10}}>
           <h3>Sign In</h3>
           <FaTimesCircle style={{cursor:'pointer'}} onClick={close} />
           </div>
           <p>  Sign in here to access VIP content including online-courses, downloads and more... </p>

               <form action="https://formspree.io/f/mrgodwgv" method="POST" className="form-group contact-form "  action="">
               <input name="name" className="form-control" type="text" placeholder="Name"/>
               
               <input name="email" className="form-control" type="text" placeholder="Email" />


               <button className="btn btn-success" type="button" >Enter</button>
               </form>

        
           </div>
       </Modal>
    )
}

export default SignInModal
