import React, { useEffect, useState } from "react";
import { getInstruments } from "../../contentful";
import "./styles.css";
import Translator from "../i18n/Translator";

import InstrumentGalleryCard from "./InstrumentGalleryCard";

const InstrumentGallery = () => {
  const [instruments, setInstruments] = useState([]);
  const [displayInsts, setDisplayInsts] = useState([]);
  const [displayCategories, setDisplayCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const [showing, setShowing] = useState("All");

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getInstruments(switchedLang).then((data) => {
      setInstruments(data);
      setDisplayInsts(data);
      setCategories([...new Set(data.map((i) => i.fields.category))]);
      setDisplayCategories([...new Set(data.map((i) => i.fields.category))]);
    });
  }, []);

  const filterInsts = (inst) => {
    setShowing(inst);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (inst === "All") {
      setDisplayInsts(instruments);
      setDisplayCategories(categories);
    } else {
      setDisplayCategories(categories.filter((c) => c === inst));
      setDisplayInsts(
        instruments.filter(
          (i) => i.fields.category.toLowerCase() === inst.toLowerCase()
        )
      );
    }
  };

  const FilterBar = () => {
    return (
      <div className='instruments__filter-bar'>
        {categories.map((i) => (
          <button
            className={
              showing === i
                ? "instruments__filter-bar-button instruments__filter-bar-button--selected"
                : "instruments__filter-bar-button"
            }
            onClick={() => filterInsts(i)}
          >
            {i}
          </button>
        ))}
        <button
          className={
            showing === "All"
              ? "instruments__filter-bar-button instruments__filter-bar-button--selected"
              : "instruments__filter-bar-button"
          }
          onClick={() => filterInsts("All")}
        >
          <Translator path='All' />
        </button>
      </div>
    );
  };

  return (
    <div className='page-content'>
      <h3 className='instruments__category'>
        <Translator path='InstrumentGallery' />
      </h3>

      <FilterBar />

      <div>
        {displayCategories.sort().map((cat) => (
          <div>
            {/* <h4 className='instruments__category'>{cat.toUpperCase()}</h4> */}
            {displayInsts
              .filter(
                (i) => i.fields.category.toUpperCase() === cat.toUpperCase()
              )
              .map((i) => (
                <InstrumentGalleryCard i={i} />
              ))}
          </div>
        ))}
      </div>
      <FilterBar />
    </div>
  );
};

export default InstrumentGallery;
