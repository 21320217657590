import React from "react";
import marked from "marked";
import { Col } from "react-bootstrap";

const video = ({ video }) => {
  const film = marked(video.fields.youtubeCode);

  return (
    <div className='video'>
      <section dangerouslySetInnerHTML={{ __html: film }} />

      <h5>{video.fields.title}</h5>
      <p>{video.fields.text}</p>
    </div>
  );
};

export default video;
