import React, { useState, useRef, useEffect } from "react";
import { getAudio } from "../contentful";
import AudioControls from "./AudioControls";
import Nick from "../assets/imgs/close_up.jpg";
import RTB from "../assets/imgs/RTB.jpg";

const AudioPlayer = ({ selectedTracks, rtb }) => {
  const [tracks, setTracks] = useState([]);
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const getDefaultImage = () => {
    return rtb ? RTB : Nick;
  };

  useEffect(() => {
    !selectedTracks
      ? getAudio().then((data) => {
          setTracks(data);
        })
      : setTracks(selectedTracks);
  }, [selectedTracks]);

  let title, artist, audioSrc, image;
  if (tracks.length) {
    title = tracks[trackIndex].fields.title;
    artist = tracks[trackIndex].fields.artist;
    audioSrc = tracks[trackIndex].fields.mp3.fields.file.url;
    image = tracks[trackIndex].fields?.image?.fields?.file?.url
      ? tracks[trackIndex].fields?.image?.fields?.file?.url
      : getDefaultImage();
  }

  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  useEffect(() => {
    console.log("running");

    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handle setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);
    if (isReady.current && isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex, isPlaying]);

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #eee), color-stop(${currentPercentage}, 'black'))
`;

  return (
    <div className='audio-player'>
      <div className='track-info'>
        <div className='audio-artwork-wrapper'>
          {image && (
            <img
              className='artwork'
              src={image}
              alt={`track artwork for ${title} by ${artist}`}
            />
          )}
        </div>
        <h4 className='title'>{title}</h4>
        {artist && <h5 className='artist'>{artist}</h5>}
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />

        <input
          type='range'
          value={trackProgress}
          step='1'
          min='0'
          max={duration ? duration : `${duration}`}
          className='progress'
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
      </div>

      <div className='tracklist'>
        {tracks.map((track, index) => (
          <div
            key={track.fields.title}
            style={{
              backgroundColor:
                trackIndex === index
                  ? "rgb(204, 152, 21)"
                  : "rgb(214, 162, 31)",
              color: trackIndex === index ? "white" : "black",
              fontWeight: 500,
            }}
          >
            <span
              onClick={() => {
                setTrackIndex(index);
                if (!isPlaying) setIsPlaying(true);
              }}
            >
              {track.fields.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AudioPlayer;
