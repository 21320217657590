import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../Context";
import "./OTAVideos.css";
import { getOTAVideos } from "../../contentful";
import marked from "marked";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";

const OTAVideos = () => {
  const { browserLang } = useContext(MainContext);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getOTAVideos(switchedLang).then((data) => setVideos(data));
  }, []);

  return (
    <div className='video'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Entdecke tolle Posaunen-Tutorials von “Nicks Online Trombone Academy” - Playalongs & Basics für jedes Niveau. "
            : "Discover dynamic Trombone Tutorials from “Nicks Online Trombone Academy” - Playalongs, Breathing Exercises and further Basics for every level."
        }
        keywords={
          browserLang === "de"
            ? "bassposaune posaune tutorials playalong basics videos naturtonbindungen atemtechnik "
            : "bass trombone tutorials playalong duets basics videos, natural slurs, breathing exercises"
        }
      />
      <h3 className='page-title'>Online Trombone Academy Videos</h3>

      {videos.map((video) => {
        let code = marked(video.fields.youtubeCode);
        return (
          <div>
            <h4>{video.fields.title}</h4>
            <p>{video.fields.text}</p>
            <section dangerouslySetInnerHTML={{ __html: code }} />
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default OTAVideos;
