import React from "react";

const photoTitle = {
  fontWeight: "bold",
  textAlign: "center",
  height: 70,
};

const Photo = ({ image }) => {
  return (
    <div className='image-card'>
      <p style={photoTitle}>{image.fields.image.fields.title}</p>
      <div className='gallery-image-wrapper'>
        <img
          src={image.fields.image.fields.file.url}
          alt={image.fields.image.fields.title}
        />
      </div>
      <small>{image.fields.image.fields.description}</small>
    </div>
  );
};

export default Photo;
