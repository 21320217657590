import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Translator from "./i18n/Translator";

const ContactForm = ({ rows = 10 }) => {
  const form = useRef();
  const emailJSID = "user_WlnDXosQ6uH6Ux18D5Pz9";

  const sendMail = (e) => {
    e.preventDefault();

    emailjs.sendForm("gmail", "website", form.current, emailJSID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
  };
  return (
    <form className='form-group contact-form ' onSubmit={sendMail} ref={form}>
      <input
        name='name'
        className='form-control'
        type='text'
        placeholder='Name'
      />

      <input
        name='reply_to'
        className='form-control'
        type='text'
        placeholder='Email'
      />

      <Translator path='WriteMessage' />
      <textarea name='message' className='form-control' rows={rows} />

      <button className='btn btn-sholl' type='submit'>
        <Translator path='Send' />
      </button>
    </form>
  );
};

export default ContactForm;
