import React, { useRef, useState, useEffect } from "react";
import { getTestimonials } from "../../contentful";
import { motion } from "framer-motion";
import Translator from "../i18n/Translator";
import "./TestimonialsSliderStyles.css";

const SlideItem = ({ item }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.8 }}
    style={{
      textAlign: "center",
      padding: 40,
      width: 700,
      maxWidth: "100%",
      margin: "auto",
    }}
  >
    {item.fields.image && (
      <img
        className='testimonial__image'
        src={item.fields.image.fields.file.url}
      />
    )}
    <h5>{item.fields.shortVersion}</h5>
    <h6>{item.fields.author}</h6>
  </motion.div>
);

const TimerSlideMs = 4000;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tests, setTests] = useState([]);
  const [length, setLength] = useState();

  useEffect(() => {
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";
    getTestimonials(lang).then((data) => {
      setTests(data);
      tests.length && setLength(data.length);
      clearInterval(timerSlider.current);

      timerSlider.current = setInterval(
        () =>
          setCurrentIndex((currentIndex) => (currentIndex + 1) % data.length),
        TimerSlideMs
      );

      return () => {
        clearInterval(timerSlider.current);
      };
    });
  }, []);

  const timerSlider = useRef(null);

  return (
    <div
      className='testimonials-container'
      style={{
        maxWidth: "100%",
      }}
    >
      <h3>
        <Translator path='Testimonials' />
      </h3>
      <div style={{ maxWidth: "100%" }}>
        {tests.map(
          (item, itemIndex) =>
            currentIndex === itemIndex && (
              <SlideItem key={itemIndex} item={item} />
            )
        )}
      </div>
    </div>
  );
};

export default Testimonials;
