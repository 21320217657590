import React, { useEffect, useState, useRef } from "react";
import marked from "marked";
import Translator from "../i18n/Translator";
import GalleryComponent from "../GalleryComponent";

const InstrumentGalleryCard = ({ i }) => {
  const [height, setHeight] = useState(0);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const moreInfoRef = useRef();

  useEffect(() => {
    setHeight(showMoreInfo ? moreInfoRef?.current?.scrollHeight : 0);
  }, [showMoreInfo]);

  return (
    <div className='instruments__card'>
      <div className='instruments__card__header__text'>
        <h4>{i.fields.name}</h4>
        {i.fields.shortDescription && (
          <p
            dangerouslySetInnerHTML={{
              __html: marked(i.fields.shortDescription),
            }}
          />
        )}
      </div>

      <div className='instruments__card__header'>
        <div
          className='instruments__card__video'
          dangerouslySetInnerHTML={{
            __html: marked(i.fields.youtubeVideo),
          }}
        />
        <div className='instruments__card__image'>
          <img src={i.fields.mainImage.fields.file.url} />
        </div>
      </div>
      <div className='instruments__card__button'>
        <button
          onClick={() => setShowMoreInfo(!showMoreInfo)}
          className='btn btn-sholl'
        >
          {!showMoreInfo ? (
            <Translator path='ShowMore' />
          ) : (
            <Translator path='Hide' />
          )}
        </button>
      </div>
      <div
        ref={moreInfoRef}
        style={{ height }}
        className='instruments__card__moreinfo'
      >
        <div>
          {i.fields.description && (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(i.fields.description),
              }}
            />
          )}
        </div>

        {i?.fields.gallery && <GalleryComponent gallery={i.fields.gallery} />}
      </div>
    </div>
  );
};

export default InstrumentGalleryCard;
