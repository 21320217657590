import React, { useState, useEffect } from "react";
import { getRollingThunderPage } from "../../contentful";
import AudioPlayer from "../AudioPlayer";
import marked from "marked";
import Translator from "../i18n/Translator";
import ContactForm from "../ContactForm";
import EmbeddedContactForm from "../EmbeddedContactForm";
import GalleryComponent from "../GalleryComponent";

const RollingThunder = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    let switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getRollingThunderPage(switchedLang).then((data) => {
      setContent(data[0].fields);
    });
  }, []);

  const groups = [
    {
      image: content?.trioImage?.fields?.file?.url,
      video: content?.trioVideo,
      title: <Translator path='TromboneTrio' />,
      text: content?.trioText,
    },
    {
      image: content?.lowBrassImage?.fields?.file?.url,
      video: content?.lowBrassVideo,
      title: <Translator path='LowBrass4tet' />,
      text: content?.lowBrassText,
    },
    {
      image: content?.mixedImage?.fields?.file?.url,
      video: content?.mixedVideo,
      title: "Mixed Ensemble",
      text: content?.mixedGroupText,
    },
  ];

  return (
    <div>
      <h3 className='page-title'>Rolling Thunder Brass</h3>

      <div className='rolling__header'>
        <div className='rolling__logo'>
          <img src={content?.logo?.fields.file.url} />
        </div>
        <AudioPlayer rtb selectedTracks={content?.mp3} />
      </div>
      {content?.mainText && (
        <div
          className='rolling__text'
          dangerouslySetInnerHTML={{ __html: marked(content?.mainText) }}
        />
      )}
      <div className='rolling__cards'>
        {groups.map((group) => (
          <div key={group.title} className='rolling__card'>
            <h4>{group.title}</h4>
            <div className='rolling__image'>
              <img src={group.image} />
            </div>
            {group.text && (
              <section
                dangerouslySetInnerHTML={{ __html: marked(group.text) }}
              />
            )}
            {group.video && (
              <section
                dangerouslySetInnerHTML={{ __html: marked(group?.video) }}
              />
            )}
          </div>
        ))}
      </div>
      <div className='rolling__percussionist'>
        <h4 className='page-title'>
          <Translator path='Percussionist' />
        </h4>
        <h6>
          <Translator path='PercussionistInfo' />
        </h6>
        <div className='rolling__percussionist__content'>
          <div className='rolling__percussionist__image'>
            <img src={content?.percussionistImage?.fields.file.url} />
          </div>
          {content?.percussionistVideo && (
            <div className='rolling__percussionist__video'>
              <section
                dangerouslySetInnerHTML={{
                  __html: marked(content?.percussionistVideo),
                }}
              />
            </div>
          )}
        </div>
      </div>

      {content.gallery && <GalleryComponent gallery={content.gallery} />}
      <EmbeddedContactForm />
    </div>
  );
};

export default RollingThunder;
