import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "../../Context";
import "./OTAAbout.css";
import marked from "marked";
import { getOTAAboutPage } from "../../contentful";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";
import Translator from "../i18n/Translator";

import SignUpForm from "../SignUpForm";

const OTAAbout = () => {
  const { browserLang } = useContext(MainContext);
  const [content, setContent] = useState([]);
  useEffect(() => {
    getOTAAboutPage().then((data) => setContent(data));
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick’s Online Trombone Academy verhilft PosaunistInnen zu einer soliden Grundlage. Buche jetzt eine Schnupperstunde. "
            : "Nick’s Online Trombone Academy is committed to providing quality educational content to the Trombone Community - specialised in giving a solid foundation."
        }
        keywords={
          browserLang === "de"
            ? "lern posaune lehrer online posaunenunterricht mönchengladbach bassposaune"
            : "learn trombone teacher online lessons bass trombone"
        }
      />
      {content.map((content) => {
        const video = marked(content.fields.video);
        const text = marked(content.fields.text);
        return (
          <div className='page-content'>
            <h3 className='page-title'>{content.fields.title}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div className='ota-logo'>
                <img src={content.fields.logo.fields.file.url} alt='' />
                <img src={content.fields.slogan.fields.file.url} alt='' />
              </div>
              <section
                style={{ width: 800 }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
            <div
              style={{ marginTop: 35, marginBottom: 35 }}
              className='top-section'
            >
              <section dangerouslySetInnerHTML={{ __html: video }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OTAAbout;
