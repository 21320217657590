import React from "react";
import Event from "../Event";
import { getCalendar, getCalendarArchive } from "../../contentful";
import Translator from "../i18n/Translator";
import { Row, Col } from "react-bootstrap";

import TitleBar from '../TitleBar'


const Calendar = () => {
 return(
   <h3>calendar</h3>
 )
}
export default Calendar;
