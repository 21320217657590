import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../Context";

import { Row, Col } from "react-bootstrap";
import { getBiog } from "../contentful";
import marked from "marked";
import TitleBar from "./TitleBar";
import HelmetConfigurator from "./HelmetConfigurator/HelmetConfigurator";

const Biog = () => {
  const { browserLang } = useContext(MainContext);
  const [content, setContent] = useState([]);
  useEffect(() => {
    let switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getBiog(switchedLang).then((data) => setContent(data));
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick Sholl studierte in vier Ländern mit renommierten Lehrern wie Ben van Dijk. Seit zehn Jahren begeistert er seine Schüler und sein Publikum als Lehrer, Posaunist und Dirigent."
            : "Nick Sholl’s studies took him across 4 countries and 2 continents seeking out world-class teachers at every step. He is committed to giving his skills and knowledge back to the trombone community."
        }
        keywords={
          browserLang === "de"
            ? "nick sholl biographie unterricht"
            : "nick sholl, biography, ben van dijk, bob hughes, martin van den berg, erik bart van lier"
        }
      />
      {content.map((content) => {
        const text = marked(content.fields.mainText);

        return (
          <div className='text-center'>
            <h3 style={{ marginBottom: 35 }}>{content.fields.title}</h3>
            <Row>
              <Col md={6}>
                <img
                  src={content.fields.mainImage.fields.file.url}
                  style={{ width: "100%" }}
                  alt='Nick'
                />
              </Col>
              <Col md={6}>
                <section
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Biog;
