import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../Context";
import { getGallery } from "../contentful";
import Translator from "./i18n/Translator";
import Photo from "./Photo";
import HelmetConfigurator from "./HelmetConfigurator/HelmetConfigurator";

import { SRLWrapper } from "simple-react-lightbox";

const Gallery = () => {
  const { browserLang } = useContext(MainContext);
  const [selectedImg, setSelectedImg] = useState(null);
  const [title, setTitle] = useState(null);

  const [images, setImages] = useState([]);

  useEffect(() => {
    let switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getGallery(switchedLang).then((data) => setImages(data));
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick Sholl hat mit den besten Posaunen-Lehrern seiner Generation gearbeitet wie Ben van Dijk, Joe Alessi und Douglas Yeo."
            : "Nick Sholl has worked with the best Trombonists of his generation including Ben van Dijk, Joe Alessi and Douglas Yeo."
        }
        keywords={
          browserLang === "de"
            ? "nick sholl bassposaune joe alessi douglas yeo ben van dijk phil teele posaune enrique crespo"
            : "nick sholl, bass trombone,  joe alessi, douglas yeo, ben van dijk, phil teele, enrique crespo"
        }
      />
      <h3 style={{ textAlign: "center" }}>
        <Translator path='PhotoGallery' />
      </h3>
      <p style={{ textAlign: "center" }}>
        <Translator path='GalleryClick' />
      </p>

      <SRLWrapper>
        <div className='gallery-container'>
          {images.map((image, i) => (
            <Photo key={i} image={image} />
          ))}
        </div>
      </SRLWrapper>
    </div>
  );
};

export default Gallery;
