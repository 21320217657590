import React from "react";
import {
  FaYoutube,
  FaSoundcloud,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Translator from "./i18n/Translator";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import logo from "../assets/logo.jpg";

const SocialButtons = () => {
  return (
    <div className='social-icons-container'>
      <hr />
      <h4>
        <Translator path='FollowNick' />
      </h4>
      <div className='social-icons'>
        <div className='social-icon-box'>
          <a
            href='https://www.instagram.com/nick.tromboneacademy/'
            target='_blank'
            rel='noopener'
          >
            <FaInstagram className='social-icon' size={44} />
          </a>
          <h5>Instagram</h5>
        </div>
        <div className='social-icon-box'>
          <a
            href='https://www.facebook.com/NicksTromboneAcademy'
            target='_blank'
            rel='noopener'
          >
            <FaFacebook className='social-icon' size={44} />
          </a>
          <h5>Facebook</h5>
        </div>
        <div className='social-icon-box'>
          <a
            href='https://www.youtube.com/channel/UCEF0Ke_MTPW0dc9Oer2q2hg'
            target='_blank'
            rel='noopener'
          >
            <FaYoutube className='social-icon' color='red' size={44} />
          </a>
          <h5>Youtube</h5>
        </div>

        <div className='social-icon-box'>
          <a
            href='https://soundcloud.com/nick-sholl'
            target='_blank'
            rel='noopener'
          >
            <FaSoundcloud className='social-icon' color='goldenrod' size={44} />
          </a>
          <h5>Nick's Soundcloud</h5>
        </div>
      </div>
      <div className='sharing-buttons-container'>
        <small>
          <Translator path='ShareThisPage' />
        </small>

        <div className='sharing-buttons'>
          <br></br>
          <TwitterShareButton
            className='sharing-button social-icon'
            url={window.location}
            children={<TwitterIcon round size={34} />}
          />
          <LinkedinShareButton
            className='sharing-button social-icon'
            url={window.location}
            children={<LinkedinIcon round size={34} />}
          />
          <FacebookShareButton
            className='sharing-button social-icon'
            url={window.location}
            children={<FacebookIcon round size={34} />}
          />
        </div>
      </div>{" "}
    </div>
  );
};

export default SocialButtons;
