import React, { useState, useEffect } from "react";
import { getLiveMusic } from "../../contentful";
import { Link } from "react-router-dom";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";
import EmbeddedContactForm from "../EmbeddedContactForm";

const LiveMusic = () => {
  const [acts, setActs] = useState([]);
  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getLiveMusic(switchedLang).then((data) => setActs(data));
  }, []);

  return (
    <>
      <div className='gallery-container'>
        <HelmetConfigurator />
        <div className='card-gallery'>
          {acts.map((act) => (
            <Link
              className='link image-card'
              to={
                act.fields.title.includes("Rolling Thunder")
                  ? "/rolling_thunder"
                  : `/livemusic/${act.sys.id}`
              }
            >
              <div>
                <h4>{act.fields.title}</h4>
                <div className='image-card__image'>
                  <img src={act.fields.mainImage.fields.file.url} alt='' />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <EmbeddedContactForm />
    </>
  );
};

export default LiveMusic;
