import React from "react";
import { Helmet } from "react-helmet";

const HelmetConfigurator = ({ description, keywords, title }) => (
  <Helmet>
    <title>{title || "Nick Sholl"}</title>
    <meta name='description' content={description} />
    <meta name='keywords' content={keywords} />
  </Helmet>
);

export default HelmetConfigurator;
