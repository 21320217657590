import React, { useState, useEffect, useRef } from "react";
import marked from "marked";
import Translator from "../i18n/Translator";
import { Link } from "react-router-dom";
import NewsStickerEn from "../../assets/imgs/newsStickerEn.png";
import NewsStickerDe from "../../assets/imgs/newsStickerDE.png";

const NewsListItem = ({ item, homepage }) => {
  const [showMore, setShowMore] = useState(false);
  const [detailsHeight, setDetailsHeight] = useState(0);
  const detailsRef = useRef();

  useEffect(() => {
    setDetailsHeight(showMore ? detailsRef.current.scrollHeight : 0);
  }, [showMore]);

  const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

  return (
    <>
      <div className='news-list-item'>
        <div className='news-list-item__text'>
          {homepage && (
            <div className='news-list-item__sticker'>
              <img src={lang === "de" ? NewsStickerDe : NewsStickerEn} />
            </div>
          )}
          <h3>{item.fields.title}</h3>
          {item.fields.subtitle && <h6>{item.fields.subtitle}</h6>}
          {/* {homepage ? (
            <button
              onClick={() => setShowMore(!showMore)}
              className='btn btn-sholl'
            >
              {!showMore ? (
                <Translator path='Show Details' />
              ) : (
                <Translator path='Hide Details' />
              )}
            </button>
          ) : (
            <Link to={`/news/${item.sys.id}`} className='btn btn-sholl'>
              <Translator path='ReadMore' />{" "}
            </Link>
          )} */}

          {!homepage && (
            <div>
              <Link to={`/news/${item.sys.id}`} className='btn btn-sholl'>
                <Translator path='ReadMore' />{" "}
              </Link>
            </div>
          )}
          {homepage && (
            <div
              ref={detailsRef}
              className='news-dropdown'
              // style={{ height: detailsHeight }}
            >
              <section
                className='news-dropdown__details'
                dangerouslySetInnerHTML={{
                  __html: marked(item?.fields?.homepageDetails),
                }}
              />
            </div>
          )}
        </div>
        {item.fields.mainImage && (
          <div className='news-list-item__image'>
            <img src={item.fields.mainImage.fields.file.url} />
          </div>
        )}
      </div>
    </>
  );
};

export default NewsListItem;
