import React, { createContext, useState, useEffect } from "react";
import { getNewsItems } from "./contentful";

const MainContext = createContext(null);

const MainProvider = ({ children }) => {
  const [contactModal, setContactModal] = useState(false);
  const [browserLang, setBrowserLang] = useState(null);
  const [isNews, setIsNews] = useState(false);
  const [isHomepageNews, setIsHomepageNews] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString();
    getNewsItems().then((data) => {
      const upComing = data.filter((item) => item.fields.date > today);
      if (upComing.length > 0) setIsNews(true);
      if (upComing.filter((item) => item.fields.homepage).length > 0) {
        setIsHomepageNews(true);
      }
    });
  }, []);

  return (
    <MainContext.Provider
      value={{
        contactModal,
        setContactModal,
        browserLang,
        setBrowserLang,
        isNews,
        isHomepageNews,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
const MainConsumer = MainContext.Consumer;
export { MainConsumer, MainContext };
export default MainProvider;
