import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import emailjs from "emailjs-com";
import Translator from "./i18n/Translator";

const SignUpForm = ({ buttonText }) => {
  const history = useHistory();
  const form = useRef();
  const emailJSID = "user_WlnDXosQ6uH6Ux18D5Pz9";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameInputName, setNameInputName] = useState("Name");
  const [emailInputName, setEmailInputName] = useState("Email");

  const [sent, setSent] = useState(false);
  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [lang, setLang] = useState("en-US");

  useEffect(() => {
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

    setLang(lang);
    if (lang === "en-US") {
      setNameInputName("Name");
      setEmailInputName("Email");
    } else {
      setNameInputName("Vorname");
      setEmailInputName("Email_Addresse");
    }
  }, []);

  useEffect(() => {
    const js = function ml_webform_success_6652591() {
      try {
        window.top.location.href =
          "https://onlinetromboneacademy.com/f/golden-sound/thank-you";
      } catch (e) {
        window.location.href =
          "https://onlinetromboneacademy.com/f/golden-sound/thank-you";
      }
    };

    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?CODE";
    document.body.appendChild(script2);
  }, []);

  const toleranceText = () => {
    console.log(lang);
    return lang === "en-US" ? (
      <p>We will never share your details and do not tolerate spam.</p>
    ) : (
      <p>Wir teilen nie deine Details und tolerieren kein Spam.</p>
    );
  };

  return (
    <div>
      {/* <p>
        To order your free copy, please contact me at:
        <a href='mailto:nicksholl1986@gmail.com'>nicksholl1986@gmail.com</a>
      </p> */}
      {/* <form
        className='form-group sign-up-form'
        // action='https://formspree.io/f/mrgodwgv'
        // method='POST'
        ref={form}
        onSubmit={sendMail}
      >
        <div className='sign-up-inputs'>
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            id='name-input'
            className='form-control form-input'
            name={nameInputName}
            type='text'
            placeholder={nameInputName}
          />

          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            id='email-input'
            className='form-control form-input'
            name={emailInputName}
            type='text'
            placeholder={emailInputName}
          />
        </div>
        <div>
          <button
            disabled={name === "" || email === ""}
            id='button'
            type='submit'
            className='btn btn-sholl'
          >
            {buttonText}
          </button>
        </div>
      </form> */}
      <div
        id='mlb2-6652591'
        class='ml-form-embedContainer ml-subscribe-form ml-subscribe-form-6652591'
      >
        <div class='ml-form-align-center'>
          <div class='ml-form-embedWrapper embedForm'>
            <div class='ml-form-embedBody ml-form-embedBodyDefault row-form'>
              <form
                class='ml-block-form'
                action='https://assets.mailerlite.com/jsonp/291432/forms/95037563123795474/subscribe'
                data-code=''
                method='post'
                target='_blank'
              >
                <div class='ml-form-formContent'>
                  <div class='ml-form-fieldRow'>
                    <div class='ml-field-group ml-field-name'>
                      <input
                        id='name-input'
                        aria-label='name'
                        type='text'
                        class='form-control'
                        data-inputmask=''
                        name='fields[name]'
                        placeholder='Name'
                        autocomplete='name'
                      />
                    </div>
                  </div>
                  <div class='ml-form-fieldRow ml-last-item'>
                    <div class='ml-field-group ml-field-email ml-validate-email ml-validate-required'>
                      <input
                        id='email-input'
                        aria-label='email'
                        aria-required='true'
                        type='email'
                        class='form-control'
                        data-inputmask=''
                        name='fields[email]'
                        placeholder='Email'
                        autocomplete='email'
                      />
                    </div>
                  </div>
                </div>
                <div class='ml-form-embedPermissions'>
                  <div class='ml-form-embedPermissionsContent default privacy-policy'>
                    {toleranceText()}
                  </div>
                </div>
                <input type='hidden' name='ml-submit' value='1' />
                <div class='ml-form-embedSubmit'>
                  <button type='submit' class='btn btn-sholl'>
                    Download
                  </button>
                  <a
                    style={{ marginLeft: 8 }}
                    href='https://onlinetromboneacademy.com/f/golden-sound'
                    target='_blank'
                    class='btn btn-sholl'
                  >
                    <Translator path='MoreInfo' />
                  </a>
                  <button
                    disabled='disabled'
                    style={{ display: "none" }}
                    type='button'
                    class='loading'
                  >
                    {" "}
                    <div class='ml-form-embedSubmitLoad'></div>{" "}
                    <span class='sr-only'>Loading...</span>{" "}
                  </button>
                </div>
                <input type='hidden' name='anticsrf' value='true' />
              </form>
            </div>
            <div
              class='ml-form-successBody row-success'
              style={{ display: "none" }}
            >
              <div class='ml-form-successContent'>
                <h4>Thank you!</h4>
                <p>The course link is in your Inbox.&nbsp;</p>
                <p>You may need to check your SPAM folder.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
