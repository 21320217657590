import React from "react";
import SignUpFormHome from "../SignUpFormHome";
import miniCourseEN from "../../assets/imgs/miniCourseEN.png";
import miniCourseDE from "../../assets/imgs/miniCourseDE.png";

const LeadMagnet = () => {
  const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

  const source = lang === "de" ? miniCourseDE : miniCourseEN;
  return (
    <div className='lead-magnet-container'>
      <div className='lead-magnet-container__image'>
        <img src={source} />
      </div>
      <SignUpFormHome />
    </div>
  );
};

export default LeadMagnet;
