export default {
  translations: {
    Tagline: "Recorder",
    PressQuote: "A charismatic virtuoso",
    Quote: "Don't waste a breath",
    Home: "Home",
    AboutNick: "About Nick",
    Contact: "Contact",
    InputNameLabel: "Enter your name",
    InputEmailLabel: "Email",
    FormText: "Want that GOLDEN sound?",
    FormText2:
      "Join Nick in a 3-part Video Masterclass on Sound and Breathing. ",
    FormText3: "Sign up here and get the Masterclass for FREE:",
    OTA: "Online Trombone Academy",
    NextLiveSession: "Next Live Session:",
    LiveMusic: "Live Music",
    Bookings: "Bookings",
    LiveSessions: "Live Events",
    PrivateLessons: "Private Lessons",
    SheetMusic: "Sheet Music",
    PhotoGallery: "Photo Gallery",
    FollowNick: "Follow Nick's Trombone Academy",
    Conductor: "Conductor",
    AboutTheAcademy: "About the Academy",
    PayWithPaypal: "Pay with Paypal to reserve your place now!",
    NextDate: "Next date:",
    NextDates: "Next dates:",
    ContactMe: "Contact Me",
    Products: "Products",
    Percussionist: "Percussionist",
    PercussionistInfo:
      "The African fire of Djembé master Awale Ouro Akpo has been a crowd-pleasing and artistic success in recent concerts!",
    ContactMotivation:
      "Contact Nick to receive a quote for your event. I would be happy to hear from you.",
    // button texts
    SignUpButton: "Send me the Masterclass Now!",
    JoinTheMailingList: "Join the Mailing List",
    DownloadFree: "Download for Free",
    Send: "Send",
    WriteMessage: "Please write your message here:",
    MoreInfo: "More Info",
    GalleryClick: "Click on the images for Fullscreen Gallery",

    ContactText:
      "I would be happy to hear from you!  Please use the form to send me a message.",
    ContactFormName: "Your name:",
    ContactFormEmail: "Your email:",
    ContactFormMessage: "Your message",
    ContactFormButton: "send",
    Biography: "Biography",
    Calendar: "Calendar",
    Archive: "Archive",
    UpComing: "Up & Coming Events:",
    Projects: "Projects",
    Education: "Education",
    Collaborations: "Collaborations",
    Media: "Video & Audio",
    Recordings: "Recordings",
    TutorialVideos: "Tutorial Videos",
    News: "News",
    MoreNews: "All News",
    Press: "Press",
    NewsletterText:
      "And don't forget to sign up to my newsletter to stay informed about my concerts and other activities!",
    NewsletterSignUp: "sign up to my newsletter",
    ViewConcert: "view concert",
    ViewArchive: "view complete archive",
    ReadMore: "read more",
    ViewVideo: "video ansehen",
    ShareThisPage: "Share this page on social media",
    BackToAllProjects: "back to all projects",
    PhotoDownload: "Downloads",
    DownloadFile: "download biography",
    LowBrass4tet: "Low Brass Quartet",
    TromboneTrio: "Trombone Trio",
    Testimonials: "Testimonials",
    InstrumentGallery: "Instrument Gallery",
    All: "All",
  },
};
