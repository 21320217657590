import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../Context";
import "./SheetMusic.css";
import { getSheetMusic, getContactFormText } from "../../contentful";
import marked from "marked";
import Translator from "../i18n/Translator";
import SignUpForm from "../SignUpForm";
import { FaDownload } from "react-icons/fa";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";
import ContactForm from "../ContactForm";

const SheetMusic = () => {
  const { browserLang } = useContext(MainContext);
  let switchedLang = window.localStorage.lang;
  if (!switchedLang) {
    switchedLang = "en-US";
  }
  const [scores, setScores] = useState([]);
  const [formText, setFormText] = useState({});
  useEffect(() => {
    getSheetMusic(switchedLang).then((data) => setScores(data));
    getContactFormText(switchedLang).then((data) =>
      setFormText(data[0].fields)
    );
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Kostenlose Posaunen-Noten zum Download: Einblas-Routinen, Melodien durch alle Tonarten und weitere Geheimnisse der Blastechnik."
            : "Download free trombone resources here: Warm-Up routines, Melodies through the keys and range extending studies. "
        }
        keywords={
          browserLang === "de"
            ? "posaunen noten einblasübungen lieder melodien blastechnik"
            : "trombone basics, routines, scale, melody studies"
        }
      />
      <div style={{ marginTop: 45 }} className='form-container'>
        <h5>{formText?.title}</h5>
        <h6>{formText?.motivation}</h6>
        <ContactForm rows={8} />
      </div>
      <h3 className='page-title'>
        <Translator path='SheetMusic' />
      </h3>
      {scores.map((score) => {
        let video;
        if (score.fields.video) video = marked(score.fields.video);

        return (
          <div className='sheet-music-card'>
            <div className='card-text'>
              <h4>{score.fields.title}</h4>
              <p>{score.fields.description}</p>
              <section dangerouslySetInnerHTML={{ __html: video }} />

              <a
                className='btn btn-sholl'
                href={score.fields.score.fields.file.url}
                target='_blank'
              >
                <Translator path='DownloadFree' />
                <FaDownload style={{ marginLeft: 9 }} />
              </a>
            </div>
            <div className='score-container'>
              <iframe
                className='pdf'
                type='application/pdf'
                src={score.fields.score.fields.file.url}
                height='200'
              ></iframe>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SheetMusic;
