import React, { useEffect, useState } from "react";
import Translator from "../i18n/Translator";
import { Link } from "react-router-dom";
import Slider from "../Slider";
import tag from "../../assets/imgs/tagImage.png";
import { Row, Col } from "react-bootstrap";

import logo from "../../assets/imgs/OTAlogo.jpg";
import SignUpFormHome from "../SignUpFormHome";

const Hero = (props) => {
  const [offsetY, setOffsetY] = useState(0);
  const [popup, setPopup] = useState(false);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPopup(true);
  //   }, 1000);
  // }, []);

  return (
    <div>
      {" "}
      <div className='hero'>
        {/* <div className={popup ? "hero-popup popup-open" : "hero-popup"}>
          <SignUpFormHome />
        </div> */}
        <div className='hero-text-box'>
          <img src={tag} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Hero;
