import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "../Context";
import { getLiveSessionsPage } from "../contentful";
import Translator from "./i18n/Translator";
import marked from "marked";
import HelmetConfigurator from "./HelmetConfigurator/HelmetConfigurator";
const LiveSessions = () => {
  const [content, setContent] = useState([]);
  const { browserLang } = useContext(MainContext);

  useEffect(() => {
    const switchedLang = window.localStorage.lang
      ? window.localStorage.lang
      : "en-US";
    getLiveSessionsPage(switchedLang).then((data) => setContent(data));
  }, []);

  return (
    <div>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Triff dich mit Nick, um 40 Minuten Live  “Posaunen Basics” zu blasen. "
            : "Meet Nick Sholl live at his “Saturday Sessions” Warm-Up Q & A Sessions. "
        }
        keywords={
          browserLang === "de"
            ? "posaune live online basics einblasen bassposaune"
            : "trombone live online basics warmup "
        }
      />
      {content.map((content) => {
        let header = marked(content.fields.header);
        return (
          <div className='text-center'>
            <div className='page-title'>
              <h3>{content.fields.title}</h3>
            </div>

            <section
              className='live-sessions-info'
              dangerouslySetInnerHTML={{ __html: header }}
            />
            <img
              src={content.fields.mainImage.fields.file.url}
              style={{ width: "60%" }}
              alt='main image'
            />
            <div style={{ marginTop: 35 }}>
              {content.fields.date.length > 1 && (
                <h3>
                  <Translator path='NextDates' />>
                </h3>
              )}
              {content.fields.date.length === 1 && (
                <h3>
                  <Translator path='NextDate' />
                </h3>
              )}
              {content.fields.date.map((date) => (
                <h4 style={{ margin: 20 }}>{date}</h4>
              ))}

              <a
                style={{ marginBottom: 35 }}
                className='btn btn-sholl-light'
                href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CEVZX2V3HBQ9U'
              >
                <Translator path='PayWithPaypal' />{" "}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LiveSessions;
