import React from "react";
import Translator from "./i18n/Translator";
import ContactForm from "./ContactForm";

const EmbeddedContactForm = () => {
  return (
    <div className='page-embedded-contact-form'>
      <h3>
        <Translator path='Contact' />
      </h3>
      <p>
        <Translator path='ContactMotivation' />
      </p>
      <ContactForm />
    </div>
  );
};

export default EmbeddedContactForm;
