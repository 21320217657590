import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../Context";
import Translator from "../i18n/Translator";
import { getVideos } from "../../contentful";
import { Row, Col } from "react-bootstrap";
import AudioPlayer from "../AudioPlayer";
import Video from "./video";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import HelmetConfigurator from "../HelmetConfigurator/HelmetConfigurator";

const Videos = () => {
  const { browserLang } = useContext(MainContext);

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";

    console.log(lang);

    getVideos(lang).then((data) => setVideos(data));
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        keywords={
          browserLang === "de"
            ? "nick sholl live video audio metropole orchestra orkest star wars bassposaune posaune"
            : "nick sholl, live video, audio, metropole orchestra orkest, star wars, bass trombone"
        }
        description={
          browserLang === "de"
            ? "Erlebe  Nick Sholl “in action” auf der Bassposaune: u.a. mit dem Metropole Orkest und natürlich….Star Wars!"
            : "See a cross-section of  Nick Sholl’s exciting bass trombone career: live with the Metropole Orchestra and as Artist in Residence at the Italian Brass Week."
        }
      />
      <Row>
        <Col md={9}>
          <div>
            {videos.map((video, i) => {
              return (
                <ScrollAnimation
                  key={i}
                  animateIn='fadeIn'
                  animateOut='fadeOut'
                >
                  <Video video={video} />
                </ScrollAnimation>
              );
            })}
          </div>
        </Col>
        <Col md={3}>
          <div className='audioplayer-container'>
            <AudioPlayer />
            <div></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Videos;
