import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../Context";
import { getTestimonials, getPrivateLessonsPage } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import Translator from "./i18n/Translator";
import HelmetConfigurator from "./HelmetConfigurator/HelmetConfigurator";

const PrivateLessons = () => {
  const [tests, setTests] = useState([]);
  const [content, setContent] = useState([]);
  const { contactModal, setContactModal, browserLang } = useContext(
    MainContext
  );

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if (!switchedLang) {
      switchedLang = "en-US";
    }
    getPrivateLessonsPage(switchedLang).then((data) => setContent(data));
    getTestimonials(switchedLang).then((data) =>
      setTests(data.filter((test) => test.fields.privateLessons))
    );
  }, []);

  return (
    <div className='content'>
      <HelmetConfigurator
        description={
          browserLang === "de"
            ? "Nick Sholl ist spezialisiert auf die Vermittlung  einer starken Basis. Buche jetzt eine Schnupperstunde, um dein Spielen auf die nächste Ebene zu bringen."
            : "Nick Sholl specialises in helping trombonists get a solid foundation. Book a trial lesson now to take your playing to the next level. "
        }
        keywords={
          browserLang === "de"
            ? "posaunenunterricht online zoom lern posaune lehrer"
            : "zoom trombone lesson, nick sholl, trial online mönchengladbach"
        }
      />
      <div className='page-title'>
        {content.map((content) => (
          <h3>{content.fields.title}</h3>
        ))}
      </div>
      <Row>
        <Col md={4}>
          {content.map((content) => (
            <img
              src={content.fields.mainImage.fields.file.url}
              style={{ width: "100%" }}
              alt='main'
            />
          ))}
        </Col>
        <Col md={8}>
          <div>
            {content.map((content) => {
              let text = marked(content.fields.mainText);
              return (
                <div
                  style={{ padding: 25 }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              );
            })}
            <button
              style={{ marginBottom: 35 }}
              onClick={() => setContactModal(true)}
              className='btn btn-sholl-light'
              type='button'
            >
              <Translator path='ContactMe' />{" "}
            </button>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 35 }}>
        {tests.slice().map((test) => (
          <div className='testimonial'>
            <p>{test.fields.testimonial}</p>
            <small>{test.fields.author}</small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivateLessons;
