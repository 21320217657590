import React, { useState, useEffect } from "react";
import { getSingleNewsItem, getNewsItems } from "../../contentful";
import Translator from "../i18n/Translator";
import NewsListItem from "../NewsListItem/NewsListItem";

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [archive, setArchive] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const lang = window.localStorage.lang ? window.localStorage.lang : "en-US";
    const today = new Date().toISOString();
    console.log(today);
    getNewsItems(lang).then((data) => {
      setNews(data.filter((item) => item.fields.date >= today));
      setArchive(data.filter((item) => item.fields.date < today));
    });
  }, []);
  return (
    <div className='page-content'>
      <h3>
        <Translator path='News' />
      </h3>

      {news.map((item) => (
        <NewsListItem item={item} />
      ))}

      {archive.length > 0 && (
        <h3>
          <Translator path='Archive' />
        </h3>
      )}
      {archive.reverse().map((item) => (
        <NewsListItem item={item} />
      ))}
    </div>
  );
};

export default NewsPage;
